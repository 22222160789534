import {getLocale} from '../../../../../../commons/selectors/environment'
import {isOwnProfile} from '../../../selectors/user'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {OrderWithSeatingOwnProps, OrderWithSeatingRuntimeProps} from './interfaces'
import {OrderWithSeating as OrderWithSeatingPresentation} from './order-with-seating'

const mapRuntime = ({state}: MemberPageContext): OrderWithSeatingRuntimeProps => ({
  isOwner: isOwnProfile(state),
  locale: getLocale(state),
})

export const OrderWithSeating = connect<OrderWithSeatingOwnProps, OrderWithSeatingRuntimeProps>(mapRuntime)(
  OrderWithSeatingPresentation,
)
