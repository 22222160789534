import React from 'react'
import {Routes} from '../../../../../commons/enums'
import {Events} from '../events'
import {MobileOrder} from '../mobile-order'
import {MobileOrderList} from '../mobile-order-list'

interface RouterProps {
  route: Routes
  mobile: boolean
}

export const Router = ({route, mobile}: RouterProps) => {
  if (mobile) {
    switch (route) {
      case Routes.ORDER_LIST:
        return <MobileOrderList />
      case Routes.ORDER:
        return <MobileOrder />
      default:
        return <Events />
    }
  }

  return <Events />
}
