import classNames from 'classnames'
import React, {PropsWithChildren} from 'react'
import {Text, TextTypography} from 'wix-ui-tpa/cssVars'
import {classes} from './event-text.st.css'
import type {EventTextProps} from './interfaces'

export const EventText = ({extraClass, faded, large, children, dataHook}: PropsWithChildren<EventTextProps>) => (
  <Text
    data-hook={dataHook}
    children={children}
    typography={TextTypography.runningText}
    className={classNames(
      classes.root,
      {[classes.faded]: faded, [classes.large]: large},
      extraClass ? extraClass : null,
    )}
  />
)
