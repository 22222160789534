import {getEventTimeZoneId} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {getLocale} from '../../../../../commons/selectors/environment'
import {getEventOrders} from '../../selectors/event'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {MobileOrderListRuntimeProps} from './interfaces'
import {MobileOrderList as MobileOrderListPresentation} from './mobile-order-list'

const mapRuntime = ({state, actions}: MemberPageContext): MobileOrderListRuntimeProps => ({
  event: state.navigation.routeParams.event,
  orders: getEventOrders(state.navigation.routeParams.event),
  locale: getLocale(state),
  timezone: getEventTimeZoneId(state.navigation.routeParams.event.event),
  internalNavigate: actions.internalNavigate,
})

export const MobileOrderList = connect<{}, MobileOrderListRuntimeProps>(mapRuntime)(
  withTranslation()(MobileOrderListPresentation),
)
