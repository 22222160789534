import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../commons/selectors/environment'
import {getEventList, getEventsTotal, hasNoEvents} from '../../selectors/events'
import {getCurrentTab} from '../../selectors/tab'
import {isOwnProfile} from '../../selectors/user'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventsList as EventsListPresentation} from './events-list'
import {EventsListRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions}: MemberPageContext): EventsListRuntimeProps => {
  const events = getEventList(state)
  const total = getEventsTotal(state)

  return {
    hasNoEvents: hasNoEvents(state),
    expandedEvents: state.expandedEvents,
    toggleEventDetails: actions.toggleEventDetails,
    loading: state.loading,
    isOwner: isOwnProfile(state),
    mobile: isMobile(state),
    events,
    closeAllEvents: actions.closeAllEvents,
    getEvents: actions.getEvents,
    hasMore: events.length < total,
    tab: getCurrentTab(state),
  }
}

export const EventsList = connect<{}, EventsListRuntimeProps>(mapRuntime)(withTranslation()(EventsListPresentation))
