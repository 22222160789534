import {withTranslation} from '@wix/yoshi-flow-editor'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {GoToOrderButton as GoToOrderButtonPresentation} from './go-to-order-button'
import {GoToOrderButtonOwnProps, GoToOrderButtonRuntimeProps} from './interfaces'

const mapRuntime = ({actions}: MemberPageContext) => ({
  internalNavigate: actions.internalNavigate,
})

export const GoToOrderButton = connect<GoToOrderButtonOwnProps, GoToOrderButtonRuntimeProps>(mapRuntime)(
  withTranslation()(GoToOrderButtonPresentation),
)
