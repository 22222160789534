import {getEventTimeZoneId} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {getLocale} from '../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {MobileOrderRuntimeProps} from './interfaces'
import {MobileOrder as MobileOrderPresentation} from './mobile-order'

const mapRuntime = ({state, actions}: MemberPageContext): MobileOrderRuntimeProps => {
  const event = state.navigation.routeParams.event
  return {
    event,
    order: state.navigation.routeParams.order,
    locale: getLocale(state),
    timezone: getEventTimeZoneId(event.event),
    internalNavigate: actions.internalNavigate,
  }
}

export const MobileOrder = connect<{}, MobileOrderRuntimeProps>(mapRuntime)(withTranslation()(MobileOrderPresentation))
