import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {DownloadTicketsButton as DownloadTicketsButtonPresentation} from './download-tickets-button'
import {DownloadTicketsButtonOwnProps, DownloadTicketsButtonRuntimeProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {downloadTicketsAction},
}: MemberPageContext): DownloadTicketsButtonRuntimeProps => ({
  tickets: state.tickets,
  mobile: isMobile(state),
  downloadTicketsAction,
})

export const DownloadTicketsButton = connect<DownloadTicketsButtonOwnProps, DownloadTicketsButtonRuntimeProps>(
  mapRuntime,
)(withTranslation()(DownloadTicketsButtonPresentation))
