import {isMobile} from '../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventDetails as EventDetailsPresentation} from './event-details'
import {EventDetailsOwnProps, EventDetailsRuntimeProps} from './interfaces'

const mapRuntime = ({state}: MemberPageContext): EventDetailsRuntimeProps => ({
  mobile: isMobile(state),
})

export const EventDetails = connect<EventDetailsOwnProps, EventDetailsRuntimeProps>(mapRuntime)(
  EventDetailsPresentation,
)
