import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {EventText} from '../../../../../commons/components/event-text'
import {useCurrencyFormatter} from '../../../../../commons/hooks/currency'
import {useCommonDateInformation} from '../../../../../commons/hooks/dates'
import {InvoiceItem, getGroupedInvoiceItems, getLabeledItemTicketPrice} from '../../../../../commons/selectors/order'
import {ticketsHaveSeats} from '../../../../../commons/selectors/tickets'
import {DownloadTicketsButton} from '../actions/download-tickets-button'
import {Divider} from '../styled-components/divider'
import {DottedDivider} from '../styled-components/dotted-divider'
import {OrderDetailsProps} from './interfaces'
import s from './order-details.scss'
import {OrderSummary} from './order-summary'
import {OrderWithSeating} from './order-with-seating'

const TableHeader = ({t}) => (
  <div className={classNames(s.row)}>
    <div className={s.colTicket}>
      <EventText>{t('order.header.ticket-type')}</EventText>
    </div>
    <div className={s.colPrice}>
      <EventText>{t('order.header.price')}</EventText>
    </div>
    <div className={s.colQuantity}>
      <EventText>{t('order.header.quantity')}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{t('order.header.total')}</EventText>
    </div>
  </div>
)

const Item = ({item, locale}: {item: InvoiceItem; locale: string}) => {
  const {t} = useTranslation()
  const {getFormattedMoney} = useCurrencyFormatter(locale)
  const ticketPrice = getLabeledItemTicketPrice(item, t, getFormattedMoney)
  return (
    <div className={s.row}>
      <div className={s.colTicket}>
        <EventText>{item.name}</EventText>
      </div>
      <div className={s.colPrice}>
        <EventText>{ticketPrice}</EventText>
      </div>
      <div className={s.colQuantity}>
        <EventText>{item.quantity}</EventText>
      </div>
      <div className={s.colTotal}>
        <EventText>{getFormattedMoney(item.total)}</EventText>
      </div>
    </div>
  )
}

export const OrderDetails = ({order, t, event, isOwner, locale}: OrderDetailsProps) => {
  const {orderNumber, invoice} = order
  const orderDate = useCommonDateInformation(orderNumber)
  const ticketsWithSeats = ticketsHaveSeats(order.tickets)

  if (ticketsWithSeats) {
    return <OrderWithSeating order={order} event={event} />
  }

  return (
    <div className={s.root} data-hook={DH.ORDER_DETAILS}>
      <DottedDivider />
      <div className={s.content}>
        <div className={s.header}>
          <EventText>{t('order.number', {orderNumber})}</EventText>
          <EventText>{t('order.date', {date: orderDate})}</EventText>
        </div>
        {isOwner ? (
          <div className={s.downloadOrderTicketsContainer}>
            <DownloadTicketsButton event={event} order={order} />
          </div>
        ) : null}
        <Divider />
        <TableHeader t={t} />
        <Divider />
        {getGroupedInvoiceItems(order).map(item => (
          <Item key={item.id} item={item} locale={locale} />
        ))}
        <Divider />
        <OrderSummary invoice={invoice} locale={locale} />
      </div>
    </div>
  )
}
