import {isMobile} from '../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventsTabs as Presentation} from './events-tabs'
import {EventTabsRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions, isRTL}: MemberPageContext): EventTabsRuntimeProps => ({
  setTab: actions.setTab,
  tab: state.tab,
  mobile: isMobile(state),
  rtl: isRTL,
  closeAllEvents: actions.closeAllEvents,
})

export const EventsTabs = connect<{}, EventTabsRuntimeProps>(mapRuntime)(Presentation)
