import {withTranslation} from '@wix/yoshi-flow-editor'
import {getLocale} from '../../../../../commons/selectors/environment'
import {isOwnProfile} from '../../selectors/user'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {OrderDetailsOwnProps, OrderDetailsRuntimeProps} from './interfaces'
import {OrderDetails as OrderDetailsPresentation} from './order-details'

const mapRuntime = ({state}: MemberPageContext): OrderDetailsRuntimeProps => ({
  isOwner: isOwnProfile(state),
  locale: getLocale(state),
})

export const OrderDetails = connect<OrderDetailsOwnProps, OrderDetailsRuntimeProps>(mapRuntime)(
  withTranslation()(OrderDetailsPresentation),
)
