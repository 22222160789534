import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {CancelRsvpButton as Presentation} from './cancel-rsvp-button'
import {CancelRsvpButtonOwnProps, CancelRsvpButtonRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  mobile: isMobile(state),
  cancelRsvp: actions.cancelRsvp,
})

export const CancelRsvpButton = connect<CancelRsvpButtonOwnProps, CancelRsvpButtonRuntimeProps>(mapRuntime)(
  withTranslation()(Presentation),
)
