import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventSummary as EventSummaryPresentation} from './event-summary'
import {EventSummaryOwnProps, EventSummaryRuntimeProps} from './interfaces'

const mapRuntime = ({state}: MemberPageContext): EventSummaryRuntimeProps => ({
  mobile: isMobile(state),
})

export const EventSummary = connect<EventSummaryOwnProps, EventSummaryRuntimeProps>(mapRuntime)(
  withTranslation()(EventSummaryPresentation),
)
