import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {DetailsButton as DetailsButtonPresentation} from './details-button'
import {DetailsButtonOwnProps, DetailsButtonRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  mobile: isMobile(state),
  navigateToDetailsPage: actions.navigateToDetailsPage,
})

export const DetailsButton = connect<DetailsButtonOwnProps, DetailsButtonRuntimeProps>(mapRuntime)(
  withTranslation()(DetailsButtonPresentation),
)
