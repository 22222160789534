import {isMobile} from '../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {AppContainer as AppContainerPresentation} from './app-container'
import {AppContainerOwnProps, AppContainerRuntimeProps} from './interfaces'

const mapRuntime = ({state}: MemberPageContext): AppContainerRuntimeProps => ({
  mobile: isMobile(state),
  route: state.navigation.route,
})

export const AppContainer = connect<AppContainerOwnProps, AppContainerRuntimeProps>(mapRuntime)(
  AppContainerPresentation,
)
