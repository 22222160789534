import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useSettings} from '../../../hooks/settings'
import settingsParams from '../../../settingsParams'
import {Divider} from '../styled-components/divider'
import {NoEventsButton} from '../styled-components/no-events-button'
import {NoEventsText} from '../styled-components/no-events-text'
import {NoEventsProps} from './interfaces'
import s from './no-events.scss'

export const NoEvents = ({navigate, url, seeOtherEvents}: NoEventsProps) => {
  const settings = useSettings()
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const onLinkClick = () => {
    if (!url && navigate) {
      navigate()
    }

    seeOtherEvents()
  }

  return (
    <div data-hook={DH.NO_EVENTS} className={isMobile ? s.mobile : null}>
      <div className={s.content}>
        <NoEventsText>{t('members-page.no-events.message')}</NoEventsText>
        <div className={s.button}>
          <NoEventsButton as="a" href={url} onClick={onLinkClick} data-hook={DH.BUTTON_GO_TO_EVENTS}>
            {settings.get(settingsParams.noUpcomingEventsLink) as string}
          </NoEventsButton>
        </div>
      </div>
      <Divider />
    </div>
  )
}
